/**
 * @name 店铺
 */
import request from '@/utils/request'

export const getStoreSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/shops/setup'
  })
}

export const getStoreList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/shops',
    params
  })
}

export const getStoreEditDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/shops/${id}/preview`
  })
}

export const getStoreDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/shops/${id}`
  })
}

export const deleteStoreDetails = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/shops/${id}`
  })
}

export const getShopSelectList = () => {
  return request({
    method: 'GET',
    url: 'cms/shops/shop_map'
  })
}

export const getShopItemTypesList = () => {
  return request({
    method: 'GET',
    url: 'cms/shops/item_types'
  })
}

export const getShopAdminUsersList = () => {
  return request({
    method: 'GET',
    url: 'cms/shops/admin_users'
  })
}

export const getShopList = () => {
  return request({
    method: 'GET',
    url: 'cms/shops/collections'
  })
}

export const getGlobalLabelList = () => {
  return request({
    method: 'GET',
    url: 'cms/shops/global_labels'
  })
}

export const postShopsFormData = (data) => {
  return request({
    method: 'POST',
    url: 'cms/shops',
    data
  })
}

export const putShopsFormData = (data, id) => {
  return request({
    method: 'PUT',
    url: `cms/shops/${id}`,
    data
  })
}

export const updateShopsOnline = (id) => {
  return request({
    method: 'POST',
    url: `cms/shops/${id}/online`
  })
}

export const updateShopsOffline = (id) => {
  return request({
    method: 'POST',
    url: `cms/shops/${id}/offline`
  })
}

/**
 * @name 门店
 */

export const getOffStoresSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/stores/setup'
  })
}

export const getOffStoresList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/stores',
    params
  })
}

export const deleteOffStoreDetails = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/stores/${id}`
  })
}

export const getOffStoredtails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/stores/${id}`
  })
}

export const getOffStoreEditDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/stores/${id}/preview`
  })
}

export const postOffStoreFormData = (data) => {
  return request({
    method: 'POST',
    url: '/cms/stores',
    data
  })
}

export const putOffStoreFormData = (data, id) => {
  return request({
    method: 'PUT',
    url: `/cms/stores/${id}`,
    data
  })
}

export const getPoiableTypeList = () => {
  return request({
    method: 'GET',
    url: '/cms/stores/poiable_types'
  })
}

/**
 * @name 短信记录
 */

export const getShopSmsRecordsSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/shop_sms_records/setup'
  })
}

export const getShopSmsRecordsList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/shop_sms_records',
    params
  })
}

/**
 * @name 店铺标签
 */

export const getShopLabelsSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/shop_labels/setup'
  })
}

export const getShopLabelsList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/shop_labels',
    params
  })
}

export const deleteShopLabelsDetails = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/shop_labels/${id}`
  })
}

export const getShopLabelsDtails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/shop_labels/${id}`
  })
}

export const getShopLabelsEditDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/shop_labels/${id}/preview`
  })
}

export const postShopLabelsFormData = (data) => {
  return request({
    method: 'POST',
    url: '/cms/shop_labels',
    data
  })
}

export const putShopLabelsFormData = (data, id) => {
  return request({
    method: 'PUT',
    url: `/cms/shop_labels/${id}`,
    data
  })
}

export const getDashboad = () => {
  return request({
    method: 'GET',
    url: '/cms/dashboard/today'
  })
}

export const getDashboardChart = (params) => {
  return request({
    method: 'GET',
    url: '/cms/dashboard/operational_stats',
    params
  })
}

/**
 * @name 配送区域
 */
export const getDeliveryZonesSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/delivery_zones/setup'
  })
}

export const getDeliveryZonesList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/delivery_zones',
    params
  })
}

export const addDeliveryZones = (data) => {
  return request({
    method: 'POST',
    url: '/cms/delivery_zones',
    data
  })
}

export const getDeliveryZonesPreview = (id) => {
  return request({
    method: 'GET',
    url: `/cms/delivery_zones/${id}/preview`
  })
}

export const editDeliveryZones = (data, id) => {
  return request({
    method: 'PUT',
    url: `/cms/delivery_zones/${id}`,
    data
  })
}

export const updateDeliveryZonesOnline = (id) => {
  return request({
    method: 'POST',
    url: 'cms/delivery_zones/batch_online',
    data: {
      ids: [id]
    }
  })
}

export const updateDeliveryZonesOffline = (id) => {
  return request({
    method: 'POST',
    url: 'cms/delivery_zones/batch_offline',
    data: {
      ids: [id]
    }
  })
}

export const deleteDeliveryZonesById = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/delivery_zones/${id}`
  })
}

export const getDeliveryZonesDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/delivery_zones/${id}`
  })
}

export const getDeliveryZonesSimpleList = (data) => {
  return request({
    method: 'GET',
    url: '/cms/delivery_zones/simple_list',
    data
  })
}

export const getFooyoDashboardOperationalData = (params) => {
  return request({
    method: 'GET',
    url: '/cms/dashboard_v2/operational_data',
    params
  })
}
export const getFooyoDashboardOdersData = (params) => {
  return request({
    method: 'GET',
    url: '/cms/dashboard_v2/orders_data',
    params
  })
}
export const getFooyoDashboardUserData = (params) => {
  return request({
    method: 'GET',
    url: '/cms/dashboard_v2/users_data',
    params
  })
}
