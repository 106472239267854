// import i18n from '@/i18n/index'

export default {
  basicInformation: [{
    prop: 'id'
  }, {
    prop: 'name'
  }, {
    prop: 'name_en'
  }, {
    prop: 'image',
    image: true
  }, {
    prop: 'updated_at'
  }]
}
